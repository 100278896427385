import { IAction } from "../../../models";
import { CLOSE_LIST, SHOW_LIST } from "./constants";

interface IListGrid {
  showList: boolean,
  targetId: string | null
}
export interface IStoreListGrid {
  listGrid: IListGrid
}

const initialState = {
  showList: false,
  targetId: null
}
export default function reducer(state: IListGrid = initialState, action: IAction) {
  const { payload } = action
  switch (action.type) {
    case SHOW_LIST: {
      return {
        ...state,
        showList: true,
        targetId: payload
      }
    }
    case CLOSE_LIST: {
      return {
        ...state,
        showList: false,
        targetId: null
      }
    }
    default:
      return {
        ...state
      }
  }
}


