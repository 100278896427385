import React from 'react';
import { categories } from '../../categories';
import './grid.scss';

const Grid: React.FC = (): any => {
  const createRows = () => {
    return categories.map((items) => {
      return (
        <div className={`item ${items.name}`} key={`grid-${items.name}`}>
          <span>{items.name}</span>
        </div>
      );
    });
  };
  return (
    <div className="desktop-grid">
      <div className="item Video">
        <span>Video</span>
      </div>
      {createRows()}
    </div>
  );
};

export default Grid;