import React, { ReactNode } from 'react';
import Form from '../form';
import mainContent from "../../config/content";
import {Link} from "react-router-dom";
import Nexticon from "../../Assets/arrow-right.png";
import Hero from "../../common/Hero/hero";
import paragraphs from "../../../../common/Paragraphs";
import StripeBtn from "../../../../common/StripeCheckout/StripeBtn";

type Paragraph = string | [string[]] | string[] | any
interface TwoColumnThreeRowListProps {
    content: {
        title: string;
        paragraph: Paragraph;
        paragraph2?: string[];
        shortTitle: string;
        serviceProductId?: string[];
    };
    index: number;
}
const stage: any = window.location.hostname === 'dropemfitness.com' ? 1 : 0

const PlanTemplate: React.FC<TwoColumnThreeRowListProps> = ({ content, index }) => {
    console.log({content});
    return (<>
            <Hero backgroundImage={`${mainContent.training.packages[index].imagePath}`} children={<></>}/>
            <div className="col-2-img plan-template container">
                <h1>{content.title}</h1>
                <div className='row w100 md'>
                    <div className='w80 col-1 container'>
                        <h4>{content.shortTitle}</h4>
                        <div className='w100 call-to-action'>
                            <StripeBtn
                                id='workout-plans'
                                customClass="call-to-action"
                                type="dropdown"
                                productId={content.serviceProductId![stage]}
                            />
                        </div>
                        {
                            Array.isArray(content.paragraph) ? content.paragraph.map((par: Paragraph, index: number) => {
                                if (Array.isArray(par)) {
                                    return <ul key={`list-${index}`}>{
                                        par.map((p: string[] | string, i: number) => {
                                            return (<li key={`list-item-${i}`}>{p}</li>)
                                        })}
                                    </ul>
                                }
                                return <p key={`content-${index}`}>{par}</p>
                            }) : <>
                                <p>{content.paragraph}</p>
                            </>
                        }
                    </div>
                    <div className='w20 col-2 form'>
                        <Form hideBackgroundImage={true}/>
                    </div>
                </div>
                <h4>See More Plans</h4>
                <div className='row w100 md'>
                    <div className="row">
                        <div className="left">
                            {
                                index >= 1 && (
                                    <Link to={`${mainContent.training.packages[index - 1].id}`}>
                                        <img src={Nexticon} alt={`previous plan`}/>
                                        {` ${mainContent.training.packages[index - 1].shortTitle}`}
                                    </Link>
                                )
                            }
                        </div>
                        <div className="right">
                            {
                                index < mainContent.training.packages.length - 1 && (
                                    <Link to={`${mainContent.training.packages[index + 1].id}`}>
                                        {`${mainContent.training.packages[index + 1].shortTitle} `}
                                        <img src={Nexticon} alt={`next plan`}/>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PlanTemplate;