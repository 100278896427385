export const categories = [
    {
        id: 1,
        name: "cars"
    },
    {
        id: 2,
        name: "apartments"
    },
    {
        id: 3,
        name: "restaurants"
    },
    {
        id: 4,
        name: "funny"
    },
    {
        id: 5,
        name: "jobs"
    },
    {
        id: 6,
        name: "music"
    },
    {
        id: 7,
        name: "hair-makeup"
    },
    {
        id: 8,
        name: "it"
    },
    {
        id: 9,
        name: "exec-support"
    },
    {
        id: 10,
        name: "sports"
    },
    {
        id: 11,
        name: "fashions"
    },
    {
        id: 12,
        name: "handy-work"
    },
    {
        id: 13,
        name: "photography"
    },
    {
        id: 14,
        name: "bgenyc-movies"
    },
    {
        id: 15,
        name: "taxi"
    },
    {
        id: 16,
        name: "social"
    },
    {
        id: 17,
        name: "live"
    },
    {
        id: 18,
        name: "post"
    }
]