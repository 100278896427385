import React from 'react';
import Checkout from './components/Checkout';

import './stripe.scss'

function CheckoutControls() {
  return (
    <>
      <Checkout />
    </>
  );
}

export default CheckoutControls;
