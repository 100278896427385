import React, { useEffect, useState } from 'react'
import { useWindowSize } from '../../../hooks/useWindowSize'
import MainContent from './components/MainContent'
// import ReactPlayer from 'react-player'
import MoviePlayer from '../../../common/Player/Player-POC'

import './styles.scss';
import content from "../config/content";

// https://youtu.be/DKVO0V1gbnU
function Landing() {
  // const [playerState, setPlayerState] = useState<boolean>(true)
  const [showPlayer, setShowPlayer] = useState<boolean>(false)
  const size = useWindowSize();

  const innerHeight = `${size[1] - 111}px`

  const onEnd = () => {
    // console.log('show me this elment', element)
    setShowPlayer(false)
  }

  useEffect(
    () => {
      // setPlayerState(true)
      if (size[0] < 750) {
        setShowPlayer(false)
      }
    },
    [size]
  )

  return <div
    className="landing-container"
  >
    {showPlayer ? <>
      <MoviePlayer tubeUrl="ZPp_hzqx-QI" innerHeight={innerHeight} hidePlayer={onEnd} />
    </> : <>
      <MainContent
          showContent={showPlayer}
          content={content}
      />
    </>
    }
  </div>
}

export default Landing;
