import dropemLogo from '../Assets/new-logo-white-text.png';
import './dropemStyles.scss';

const pageSetup = {
  rootClassName: 'dropemStyles',
  header: {
    classStyles: 'dropem-header'
  },
  logo: {
    img: dropemLogo
  }
};

export default pageSetup;
