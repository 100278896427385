import React, { ReactNode } from 'react';
import './coachPageStyles.scss';
import content from "../../config/content";
import { Link } from 'react-router-dom';
import CoachPlanLink from "./coach-plan-link";

interface TwoColumnThreeRowListProps {
    children: ReactNode[];
}


const TwoColumnThreeRowList: React.FC<TwoColumnThreeRowListProps> = ({ children }) => {
    return (
        <div className="coachPage">
            <h1>{content.training.title}</h1>
            <div className="twoColumnThreeRowList">
                {content.training.packages.map((child, index) => {
                    return (
                        <Link to={`/packages/${child.id}`}>
                            <CoachPlanLink content={child}/>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default TwoColumnThreeRowList;