import React from 'react';
import TestimonialSlider from '../../../../common/TestimonialSlider'

import content from '../../config/content';

const { testimonials } = content
function Testimonials() {
  return (
    <div className="testimonials container">
      <h1>{testimonials.headerText}</h1>
      <TestimonialSlider testimonials={testimonials.reviews} />
    </div>
  )
}

export default Testimonials
