interface ImageDivProps {
    content: {
        imagePath: string;
        title: string;
    };
}

const CoachPlanLink: React.FC<ImageDivProps> = ({content: {imagePath, title}}) => {
    return (
        <div
            className="coachPageLink divStyle"
            style={{'--background-image': `url(${imagePath})`} as any}
        >
            <div></div>
            <h3>{title}</h3>
        </div>
    );
};

export default CoachPlanLink;