import React from 'react'
import CheckoutControls from '../../../../common/StripeCheckout';
import { useSliderReducer } from '../../../../common/Slider/store/useSliderReducer'


import bmx6 from "./b6.jpg"
// Plaecholders
const imgSrc = bmx6
const itemDesc = "The BMW X6 makes an unshakeable impression with its wide stance, horizontal contours, impressive air intakes, and distinctive headlight designs. Leading lights. The optional Illuminated Kidney Grille adds dramatic distinction to the available Icon Adaptive LED Headlights with Laserlight."

function ProductDetail() {
  const { targetId } = useSliderReducer();

  return <div className="cancel-container">
    <h3>{targetId}</h3>
    <h1>$75K BMW 6</h1>
    <img src={imgSrc} alt={``} />
    <p>{itemDesc}</p>
    <CheckoutControls />
  </div>
}

export default ProductDetail
