import React from 'react'
import BackBtn from '../../../common/Buttons/Back'

function Cancel() {
  return <div className="cancel-container">
    <h1>Checkout Cancelled</h1>
    <BackBtn />
  </div>
}

export default Cancel
