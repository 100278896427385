import React, { useState, useEffect } from 'react'
import { Dropdown, Button, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import { getProductPrices, fetchCheckoutSession } from '../../api/stripe';
import { useStripeReducer } from './store/useStripeReducer'
import { loadStripe } from '@stripe/stripe-js';
import { isEmpty } from 'lodash';

type Props = {
  id?: string,
  customClass?: string
  productId: string,
  type: string,
  label?: string
}

function StripeBtn({ id = '', customClass = '', productId, type, label = "" }: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const { products, pk_stripe, stripe, dataDispatch, actions: { UPDATE_INTERNAL_PRODUCTS, SET_STRIPE_INSTANCE } } = useStripeReducer()
  useEffect(
    () => {
      async function setUp() {
        if (pk_stripe) {
          const stripeInstance = await loadStripe(pk_stripe)
          dataDispatch(SET_STRIPE_INSTANCE, stripeInstance)
        }
      }

      if (!stripe && pk_stripe !== null) {
        setUp()
      }
    },
    [pk_stripe]
  )
  useEffect(() => {
    async function getPrices() {
      setLoading(true)
      const result = await getProductPrices(productId)
      dataDispatch(UPDATE_INTERNAL_PRODUCTS, {
        productdId: result.productId,
        options: result.options
      })
      setLoading(false)
    }
    if (!products[productId]) {
      getPrices()
    }
  },
    [productId]
  )

  const activateCheckout = async (e: any) => {
    // e.preventDefault()
    const [priceId, mode] = e.key.split('-')
    setLoading(true)
    const { id } = await fetchCheckoutSession({ priceId, mode })

    const { error } = await stripe.redirectToCheckout({
      sessionId: id
    });

    console.log('&&&&&&&&&&&&', error)
    setLoading(false)
  }

  const prices = (
    <Menu onClick={activateCheckout}>
      {
        products[productId] && products[productId].map((item: any) => <Menu.Item key={`${item.priceId}-${item.mode}`} >
          {`$ ${Number((item.unitAmount) / 100).toFixed(2)}`} {item.nickname}
        </Menu.Item>)
      }
    </Menu>
  )
  const singlePriceClick = () => {
    const target = {
      key: `${products[productId][0].priceId}-${products[productId][0].mode}`
    }
    activateCheckout(target)
  }
  return <>
    {
      type === 'dropdown' ? <Dropdown overlay={prices}>
        <Button
          id={id}
          className={`${customClass}`}
          type="primary"
        >
          {
            loading ? '...loading' : 'Pricing Options'
          } <DownOutlined />
        </Button>
      </Dropdown>
        : <>
          <Button
            id={id}
            className={`${customClass}`}
            onClick={singlePriceClick}
            type="primary"
          >
            Purchase Plan
            {
              loading ? ' ...loading' : ` $ ${products[productId] && Number((products[productId][0].unitAmount) / 100).toFixed(2)}`
            }
          </Button>
        </>
    }
  </>
}

export default StripeBtn
