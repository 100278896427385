import { IAction } from "../../../models";
import { CLOSE_SLIDER, SHOW_SLIDER } from "./constants";

interface ISlider {
  showSlider: boolean,
  targetId: string | null
}
export interface IStoreSlider {
  slider: ISlider
}

const initialState = {
  showSlider: false,
  targetId: null
}
export default function reducer(state: ISlider = initialState, action: IAction) {
  const { payload } = action
  switch (action.type) {
    case SHOW_SLIDER: {
      return {
        ...state,
        showSlider: true,
        targetId: payload
      }
    }
    case CLOSE_SLIDER: {
      return {
        ...state,
        showSlider: false,
        targetId: null
      }
    }
    default:
      return {
        ...state
      }
  }
}


