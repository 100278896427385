import { IAction } from "../../models";
import { CLOSE_MODAL, SHOW_MODAL } from "./constants";

interface IMobile {
  showModal: boolean,
  catId: string | null
}
export interface IStoreMobile {
  mobileApp: IMobile
}

const initialState = {
  showModal: false,
  catId: null
}
export default function reducer(state: IMobile = initialState, action: IAction) {
  const { payload } = action
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        ...state,
        showModal: true,
        catId: payload
      }
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        showModal: false,
        catId: null
      }
    }
    default:
      return {
        ...state
      }
  }
}
