import React from "react";
import { useSliderReducer } from '../Slider/store/useSliderReducer'

import "./slider.scss";

function Slider(props: any) {
  const { showSlider } = useSliderReducer();
  return (
    <div className={`mobile-slider ${showSlider ? 'showSlider' : ''}`}>
      {
        props.children
      }
    </div >
  )
}

export default Slider;
