import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import { useMobileReducer } from '../store/useMobileReducer'
import { useSliderReducer } from '../../common/Slider/store/useSliderReducer'

function MobileModal() {
    const history = useHistory();
    const { catId, showModal, actions: { CLOSE_MODAL } } = useMobileReducer();
    const { dataDispatch, actions: { SHOW_SLIDER } } = useSliderReducer();

    const showShop = (event: any): void => {
        dataDispatch(CLOSE_MODAL);
        dataDispatch(SHOW_SLIDER, catId);
        history.push(`/${catId}`)
    }
    return (
        <>
            <Modal
                title={catId}
                centered
                visible={showModal}
                onCancel={() => dataDispatch(CLOSE_MODAL)}
                footer={null}
                width={1000}
            >
                <h1>This is a Video Mobile</h1>
                <Button
                    type="primary"
                    icon={<ShopOutlined />}
                    onClick={showShop}
                >
                    Shop
                </Button>
            </Modal>
        </>
    );
};

export default MobileModal;
