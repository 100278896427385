import React from 'react';
import { useListGridReducer } from '../common/ListGrid/store/useListGridReducer'
import VideoModal from './videoModal';
import { categories } from '../categories';

import './square.scss';

function Mobile(): JSX.Element {
    const { dataDispatch, showList, actions: { SHOW_LIST } } = useListGridReducer();

    const goToProducts = (e: any) => {
        e.preventDefault()
        const { id } = e.target
        dataDispatch(SHOW_LIST, id)
    }
    const createRows = () => {
        return categories.map(item => {
            const { name } = item
            return (
                <button
                    id={name}
                    className={`item ${name}`}
                    key={'grid' + name}
                    onClick={goToProducts}
                >
                    <span id={name}>{name}</span>
                </button >
            )
        });
    };
    return <div className={`mobile-grid ${showList ? 'fadeOut' : ''}`} >
        {createRows()}
        <VideoModal />
    </div>
}

export default Mobile;
