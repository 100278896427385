import React from 'react'
import { useHistory } from "react-router";
import { useSliderReducer } from '../../../../common/Slider/store/useSliderReducer'

import { Button } from 'antd'

function BackBtn() {
  const history = useHistory();
  const { dataDispatch, actions: { CLOSE_SLIDER } } = useSliderReducer();

  const goBack = () => {
    dataDispatch(CLOSE_SLIDER)
    history.push(`/`)
  }
  return <>
    <Button
      onClick={goBack}
    >
      Back
    </Button>
  </>
}

export default BackBtn
