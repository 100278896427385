import React, { ReactNode } from 'react';
import './hero.scss';

interface HeroProps {
    backgroundImage: string;
    children: ReactNode;
}

const Hero: React.FC<HeroProps> = ({ backgroundImage, children }) => {
    
    const heroStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        maxHeight: '500px',
        height: '100vh',
        '@media (max-width: 768px)': {
            height: '100%',
        },
    };

    return (
        <div className="hero" style={heroStyle}>
            {children}
        </div>
    );
};

export default Hero;
