import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useSliderReducer } from '../common/Slider/store/useSliderReducer'

import Header from '../container/Header';
import Routes from './Routes';
import Logo from './Logo';
import SliderContainer from './components/Slider';
import Store from './store'
import ListGrid from '../common/ListGrid';

import './App.scss';
import 'antd/dist/antd.css';

function App() {
  const { dataDispatch, actions: { SHOW_SLIDER } } = useSliderReducer();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.length > 1) {
      const targetParam = location.pathname.substring(1)
      dataDispatch(SHOW_SLIDER, targetParam)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="App BGE">
      <Header >
        <Logo />
      </Header>
      <Routes />
      <ListGrid />
      <SliderContainer />
    </div>
  );
}

// eslint-disable-next-line
export default { App, Store };

