import { IAction } from "../../../models";
import { UPDATE_INTERNAL_PRODUCTS, SET_STRIPE_PUBLIC_KEY, SET_STRIPE_INSTANCE } from './constants'

interface StripeProps {
  products: any,
  stripe: any,
  pk_stripe: string | null
}

export interface IStripe {
  stripe: StripeProps
}

const initialState = {
  stripe: null,
  pk_stripe: null,
  products: {}
}

export default function reducer(state: StripeProps = initialState, action: IAction) {
  const { payload } = action
  switch (action.type) {
    case SET_STRIPE_INSTANCE:
      return {
        ...state,
        stripe: payload
      }
    case SET_STRIPE_PUBLIC_KEY:
      return {
        ...state,
        pk_stripe: payload
      }
    case UPDATE_INTERNAL_PRODUCTS:
      const { productdId, options } = payload
      const updateProd = state.products
      if (!updateProd[productdId]) {
        updateProd[productdId] = options
      }
      return {
        ...state,
        products: {
          ...updateProd
        }
      }
    default:
      return {
        ...state
      }
  }
}