import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from '../Home';

import { categories } from '../../categories';

function Routes() {
  const createRoutes = () => {
    const catRoutes = categories.map(category => {
      const { name } = category
      const path = `/${name}`
      return (<Route exact key={name} path={path} />)
    })
    return catRoutes;
  }
  return (<>
    <Switch>
      {createRoutes()}
      <Route exact key="home" path="/" component={Home} />
    </Switch>
  </>);
}

export default Routes;
