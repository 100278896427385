import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_INTERNAL_PRODUCTS, SET_STRIPE_PUBLIC_KEY, SET_STRIPE_INSTANCE } from './constants';
import { IStripe } from './reducer';

export function useStripeReducer() {
  const StripeStore = useSelector(({ stripe }: IStripe) => stripe)
  const dispatch = useDispatch();
  const dataDispatch = (actionType: string, data?: any) => dispatch({ type: actionType, payload: data })
  return {
    ...StripeStore,
    actions: {
      UPDATE_INTERNAL_PRODUCTS,
      SET_STRIPE_PUBLIC_KEY,
      SET_STRIPE_INSTANCE
    },
    dataDispatch,
    dispatch
  }
}
