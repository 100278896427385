import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic, rootReducer } from './reducer';

export default function configureStore() {
  const epicMiddleware = createEpicMiddleware();
  const middleware = [epicMiddleware];
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(...middleware),
    ),
  );

  epicMiddleware.run(rootEpic)

  return store;
}
