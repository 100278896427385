import { useSelector, useDispatch } from 'react-redux';
import { CLOSE_SLIDER, SHOW_SLIDER } from './constants';
import { IStoreSlider } from './reducer';

export function useSliderReducer() {
  const sliderStore = useSelector(({ slider }: IStoreSlider) => slider)
  const dispatch = useDispatch();
  const dataDispatch = (actionType: string, data?: any) => dispatch({ type: actionType, payload: data })
  return {
    ...sliderStore,
    actions: {
      CLOSE_SLIDER,
      SHOW_SLIDER
    },
    dataDispatch,
    dispatch
  }
}


