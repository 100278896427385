import HelloRenald from "../Assets/Renald-hello.png";
import igDiet from "../Assets/package-plans/diet-photo.png";
import igCoach from "../Assets/about-me.jpg";
import landing1 from "../Assets/landing-1.jpg";
import why2 from "../Assets/why-2.jpg";
import why1 from "../Assets/why-1.jpg";
import why3 from "../Assets/why-3.jpg";
import testimonialImg from "../Assets/testimonial-1.png";
import testimonialImg2 from "../Assets/testimonial.png";
import testimonialImg3 from "../Assets/testimonial-2.png";
import TestimonialAnna from "../Assets/ana-main.png";

import packagePlanImages from "../Assets/package-plans"
import show from "../Assets/show";

const primaryPath: string = "/coaching";
const stage: any = window.location.hostname === "dropemfitness.com" ? 0 : 1;

const stripeEnv = [
  {
    key: "pk_live_51JwfLrCSx93mwrM61cipg5CV97oparJBdNZ1DC0qUuj2BXELoYs5dKOhnxKw5xPnXSh9DB4PEQewyj85hDlP3qHf00hx5h1ZnS",
    products: {
      diet: "prod_KpgCXLxef1M1xa",
      online: "prod_Ko9jH1di3C2Dxv",
      onsite: "prod_M8vMSqtT8yhdbf",
    },
  },
  {
    key: "pk_test_51JwfLrCSx93mwrM6hQME6b1TqpMu6E1gksGe3K7essbtDFJehA0MtoQTStpLu38ukHs4WVMGboLt5GprHlEKrBJs00WB9uS8BL",
    products: {
      diet: "prod_KrGK2HyomvPbDU",
      online: "prod_KsDI5rCsuQkYvf",
      onsite: "prod_KsDKfQ3QneBjRe",
    },
  },
];

const content = {
  stripePublicKey: stripeEnv[stage].key,
  primaryPath,
  calendyLinkText: "Schedule a Consultation",
  landing: {
    headerText: "Welcome to Drop Them Fitness",
    par: [
      "If you're ready for a change in your life and need guidance on your journey. Whether it's building muscle, shedding fat or simply eating better and moving your body to a healthier you. you’ve landed to  the right place. ",
    ],
    button: {
      type: "primary",
      path: primaryPath,
      text: "Read More",
    },
    image: [
      {
        name: "renald-welcome-image",
        src: HelloRenald,
      },
    ],
  },
  aboutUs: {
    headerText: "Coach Renald",
    par: [
      "I embarked on my fitness journey during my high school years at the age of 14. It all began in the library where I frequent anime and manga material, flipping through magazines like Men's Health and Muscular Development. It was in the pages of MD that I first laid eyes on Dexter Jackson, the reigning Mr. Olympia champion at the time. I was captivated by his impressive physique and the enticing lifestyle of bodybuilding, and that's when my passion for fitness ignited.",
      "Fast forward more than a decade, and I've amassed a wealth of knowledge, with a perpetual thirst for learning. I firmly believe that there's no one-size-fits-all solution when it comes to nutrition and exercise. My approach is grounded in the fundamentals, and I don't aim to reinvent the wheel. I'm a strong advocate for achieving a sculpted physique or that coveted Coca-Cola bottle frame through proper nutrition, free weights (resistance training), and cardio. Today, I find great joy in assisting individuals, from beginners to those at an intermediate level, on their fitness journey.",
      "Enter 'Drop Them Fitness' (DTHF). Here at DTHF, my mission is to educate, motivate, and share the wealth of knowledge I've acquired throughout my personal fitness journey. With the confidence born from my experiences and expertise, I'm committed to helping you attain your fitness goals."
    ],
    ig: {
      src: why2,
    },
    button: {
      type: "primary",
      path: "", // workout path
      text: "Workout Plans",
    },
  },
  dietPlans: {
    productId: stripeEnv[stage].products.diet,
    headerText: "Base Diet Nutrition Plan",
    par: [
      "This plan is meant to give you jump start into your journey.  I will write up a plan specific to you so communication is key to not hide anything so I may better help you.  You will fill out a detailed questionnaire  that I will send to you via email which ranges from food:",
    ],
    list: [
      "Food likes/dislikes",
      "Workout and diet experience",
      "Health history",
      "Allergens/medical issues",
      "Most recent Blood work/labs",
    ],
    par2: [
      "Along with all this info you will attach pictures so I may see your current level of muscle tissue and body fat.Based off this data, I will build you a customized plan to reach your fitness goals. Each plan includes a recommended time, macronutrient breakdown for each meal of the day, as well as recommended supplements for general health. Cardio program can be provided if needed.",
      "Your plan can be the traditional 3 meals a day too 6-8 smaller meals as long as you are getting your calorie needs for what you are trying to do the method that you follow for your busy lifestyle won't matter.  After receiving the plan can ask me any and all questions you have to best maximize the program to your needs.",
    ],
    ig: {
      src: igDiet,
    },
    list2: {
      title: "Highlights of the Base Diet",
      bullets: [
        "Fat Loss",
        "Muscle Gain",
        "Overall Health(fix your blood work/labs)",
        "Sport specific(powerlifting, Fighter, Bodybuilding)",
      ],
    },
    callToAction: ["One Time Base Diet Plan $150"],
    button: {
      type: "primary",
      path: `/Forms/1FAIpQLSeMn1fvVvzgurnzunOJapfZxTxQOdj9DFbyTneqVn0NZwxERg`, // workout path
      text: "Nutrition Plan",
    },
  },
  testimonials: {
    headerText: "Client Testimonials",
    reviews: [
      {
        image: {
          src1: testimonialImg,
          src2: testimonialImg2,
          src3: testimonialImg3,
        },
        items: [
          {
            title: "",
            name: "Hevinly",
            product: "12 Week Program",
            message: [
              "Honestly I was beyond impressed with the meal plan that was also pcos friendly. The knowledge on the condition alone made me feel like I was not out of luck with my goals or that they were not impossible. And even knowing what the condition was and how tricky it can be.",
              "I'm Feeling Strong!",
            ],
            images: [
              {
                title: "Selfie",
                src: "",
              },
            ],
          },
        ],
        button: {
          type: "primary",
          path: "",
          text: "Fitness Program",
        },
      },
      {
        image: {
          src1: TestimonialAnna,
          src2: TestimonialAnna,
          src3: TestimonialAnna,
        },
        items: [
          {
            title: "",
            name: "Ana",
            product: "12 Week Program",
            message: [
              "Thank you thank you thank you! In such a little time I was able to feel a difference. I felt confident, I had more energy than I did before, and I felt excited to work out again. You helped transform not just my body but my mind. I was seeing the difference in my clothes and how they fit me. Thank you for taking the time to be my trainer.",
              "Energy at Wow Status!",
            ],
            images: [
              {
                title: "Selfie",
                src: "",
              },
            ],
          },
        ],
        button: {
          type: "primary",
          path: "",
          text: "Fitness Program",
        },
      },
    ],
  },
  workoutPlans: {
    movie: {
      tubeLink: "https://youtu.be/DKVO0V1gbnU",
    },
    formLink: "1FAIpQLSe5WOBw1qqBbOhhyZrs0NpTiFzR7RXEmflAOKU0MvbZMCARhg",
    headerText: "Coaching",
    online: {
      productId: stripeEnv[stage].products.online,
      title: {
        type: "h1",
        text: "Online",
      },
      title2: {
        type: "h2",
        text: "Fully Customized Program made for YOU:",
      },
      lists: [
        {
          activities: [
            "Program tweaks",
            "Cardio routine",
            "Customized Nutrition",
            "Includes initial consultation phone call via zoom",
            "Discussing all goals and needs for your customized program",
            "you would send photos for Weekly updates",
            "Unlimited Emails. Please allow 24hrs for a response",
            "Must have Access to a gym",
          ],
        },
        {
          equipment: [
            "Bench",
            "Yoga Mat",
            "Adjustable Dumbbell SetS",
            "Kettlebell set",
            "Goruck Sandbags",
            "Ab Roller",
            "Medicine Ball",
            "Resistance Ball",
            "Pull Up Bar",
            "Pull up Resistance bands",
            "TRx bands (optional)",
          ],
        },
        {
          cardio: [
            "1. Outside",
            "2. Cycle Bike",
            "3. Treadmill",
            "4. Jump Rope",
            "5. Battle Ropes",
          ],
        },
        {
          questionaire: [
            "After purchase a questionnaire will be emailed to you this will better help me understand how to create your plan",
            "Custom Plan is sent 24hrs after I receive your answers",
          ],
        },
      ],
      title3: {
        type: "h2",
        text: "If you do not have a gym facility you will need to have some of the equipment below",
      },
      title4: {
        type: "h2",
        text: "Cardio",
      },
      button: {
        type: "primary",
        path: `/Forms/1FAIpQLSe5WOBw1qqBbOhhyZrs0NpTiFzR7RXEmflAOKU0MvbZMCARhg`, // workout path
        text: "Get Started",
      },
      products2: [
        {
          name: "1 on 1 coaching",
          price: null,
        },
        {
          name: "1 time base diet",
          price: null,
        },
      ],
    },
    onsite: {
      productId: stripeEnv[stage].products.onsite,
      headerText: "Personal Training",
      title: {
        type: "h1",
        text: "This is for training/workouts with me only",
      },
      title2: {
        type: "h2",
        text: "My Available Hours",
        list: ["4PM-7PM Weekdays", "6AM-2PM Weekends"],
      },
      title3: {
        type: "h2",
        text: "Here is list of what you will get below",
      },
      items: [
        "We will meet in person and workout in a public gym.",
        "3 complimentary sessions to assess your balance strength and knowledge.",
        "Once goal is discussed based on your feed back a  customized program will be created for YOU",
        "Program tweaks",
        "Cardio routine",
        "Detailed layout",
        "Include initial consultation/meeting — discuss all goals and needs for your customized program",
        "Unlimited e-mails/ phone",
        "Pictures have to be sent weekly to monitor progress",
        "Sessions are 1 hour",
      ],
      title4: {
        type: "h2",
        text: "Training Session Packages",
      },
      items2: [
        "3 Sessions per week",
        "12 weeks    $1800",
        "16 week      $2400",
        "Workout programs will be provided and shared with google docs",
      ],
      title5: {
        type: "h2",
        text: "NO REFUNDS",
      },
      items3: [
        "**Sessions each week do not rollover",
        "**Wear comfortable clothes, Be ready to work!",
      ],
      button: {
        type: "primary",
        path: `/Forms/1FAIpQLSdqfDw03hEKzyu-WGyGQtKKNEqoc-xxXHLcyj-5JMJeVQW8qQ`, // workout path
        text: "Get Started",
      },
    },
  },
  WhyChooseContent: {
    title: "WORDS FROM COACH RENALD",
    contents: [
      {
        part1: {
          image: why1,
          para: `I put my clients' needs and goals at the forefront of my business. Understand their unique fitness levels, preferences, and objectives. Offer personalized training and nutrition plans that cater to individual goals, whether it's weight loss, muscle gain, or overall health improvement.<br /><br />
          Provide excellent customer service, actively listen to feedback, and make adjustments to meet clients' evolving needs. I am ISSA Certified Bodybuilding Specialist 2022 and I was mentored by top nutritionists, dietitians, strength Coaches for the last 4 years. I myself have been living the lifestyle of bodybuilding since I was 14. I continuously invest in my professional development.<br /><br />
          Stay up-to-date with the latest fitness trends, scientific research, and industry best practices to provide cutting-edge services. I put the safety and well-being of my clients first at all times. Educate clients on proper form and techniques to prevent injuries during workouts. Develop a thorough health screening process to identify any pre-existing conditions or limitations.<br /><br />
          If you're located in my vicinity or open to attending in-person sessions, we can explore this option further during our conversation. Now, let me outline the steps to get started with my coaching services.`,
        },
      },
      {
        part2: {
          image: show.bicepLong,
          para: `Step 1. Purchase one of the desired coaching services<br/><br/>
                Step 2. Complete the registration form, install the required applications, and ensure consistency by using the same email address for all components.<br/><br/>
                Step 3. After I receive your form Give me 24-48 hrs to respond with your plan via Apps.<br/><br/>
                Optional you can schedule a call if you prefer to discuss game plan LiIVE.<br/><br/>
                <b>Virtual home workouts you must schedule a call as everything will be visual</b><br/><br/>
                If you have questions and like to talk before making your decision you can schedule a call that is FREE no question is dumb ask away
                `,
        },
      },
    ],
  },
  training: {
    title: "Coaching Plans",
    packages: [
      {
        id: "nutrition-plan",
        type: "image",
        imagePath: packagePlanImages.nutrition,
        title: "Customized Nutrition",
        shortTitle: "Customized Nutrition Plan",
        paragraph: [
            "My tailored nutrition plans are designed to give you the perfect kickstart on your fitness journey. I believe in transparent communication to better understand your unique needs, allowing me to provide the most effective support.",
            "You'll receive a comprehensive questionnaire via Google Forms, covering various aspects of your lifestyle and health, including:",
          [
            "- Your food preferences and dislikes.",
            "- Your past experience with workouts and diets.",
            "- Your health history.",
            "- Any allergies or medical conditions.",
            "- Recent blood work and lab results.",
            "- I'll also ask you to share images of your physique",
          ],
            // [
            // "Always use a FLASH in the pics – I don’t want shadows.",
            // "Take them in front of a PLAIN WHITE background.",
            // "Send a FRONT and BACK pic – full body (head to toe)",
            // "Always use a FLASH in the pics – I don’t want shadows.",
            // "Take them in front of a PLAIN WHITE background.",
            // "Send a FRONT and BACK pic – full body (head to toe)",
            // "For MEN – wear just shorts (not long shorts – I want to see your thighs).",
            // "For WOMEN – wear shorts (not long shorts – I want to see your thighs) and a sports bra.",
            // "Also, always resize the pics so they are about the size of a postcard on my screen."
            // ],
          "With this invaluable information, I'll craft a personalized plan that aligns perfectly with your fitness goals. Each plan includes recommendations for meal timing, macronutrient distribution for each meal, and suggestions for general health supplements. If you require a cardio program, we can include that as well.  There will also be instructions on how to adjust calories and macros depending on your progress week to week.",
          "Whether you prefer the classic 3-meals-a-day approach or the convenience of 6-8 smaller meals, I will ensure your calorie needs are met. Your lifestyle and schedule are taken into account.\n",
          "Upon completing your purchase, you will be given the opportunity to schedule a 30-minute phone consultation with me. During this call, I will guide you through your personalized diet plan, ensuring clarity and addressing any questions you may have. I'm here to help you maximize the program according to your specific requirements. You can end your subscription at any time.\n",
        ],
        serviceProductId: ["prod_PrMwlLZfMrnDeH", "prod_Ps7lqKlD3wsBEK"],
        form: { /** Lets add a drop down menu so the client can pick the diet they would like to focus on.
           Paleo
           Vegan
           * */},
      },
      {
        id: "bulk-plan",
        type: "image",
        imagePath: packagePlanImages.bulk,
        title: "Bulk Up",
        shortTitle: "Bulk Up Plan",
        paragraph: "This program is designed and based on my Ebook Bulk Up for those who want to add some Size, but not fat.  The focus is on lean muscle gain. This could be a novice, or someone who is simply stuck and needs to break through a plateau.\n" +
            "Initial assessment\n" +
            "Customized nutrition, and supplement program designed to help you gain muscle without fat based on your goals and aspirations\n" +
            "Weekly progress assessments. Changes made based on weekly Pics, measurements, daily weigh ins \n" +
            "Pre recorded Loom Video weekly  Check-Ins\n" +
            "Specialized workouts if you have a body part in mind to bring up\n" +
            "Unlimited email support (allow 24-48 hours for responses)\n" +
            "Please allow UP TO 7 DAYS for your initial new program to be sent.",
        serviceProductId: ["prod_PrZhSz5SekCG0V", "prod_Ps7lbe2RflrDCU"],
        form: { /** Lets add a drop down menu so the client can pick the diet they would like to focus on.
           Paleo
           Vegan
           * */},
      },
      {
        id: "beach-plan",
        type: "image",
        imagePath: packagePlanImages.beachBody,
        title: "Beach Ready",
        shortTitle: "Beach Ready Plan",
        paragraph: [
            "Our program is tailored for those looking to slip back into their favorite old jeans, shine on vacation, or make a stunning entrance at their high school reunion. Whether you're a beginner or someone seeking to break through a plateau, our package is designed to help you achieve your goals.",
            "Initial assessment",
            [
              "Customized nutrition, cardio, and supplement program designed to help you lose body fat and gain muscle based on your goals and aspirations",
              "Pre recorded Loom Video weekly  Check-Ins",
              "Weekly progress assessments. Changes made based on weekly Pics, measurements, daily weigh ins",
              "High Intensity Workouts",
              "Unlimited email support (allow 24-48 hours for responses)",
              "Please allow UP TO 7 DAYS for your initial new program to be sent."
            ]
        ],
        serviceProductId: ["prod_PrZnkgvt9wmlos", "prod_Ps7lmRpOb6F28X"],
        form: { /** Lets add a drop down menu so the client can pick the diet they would like to focus on.
           Paleo
           Vegan
           * */},
      },
      {
        id: "virtual-plan",
        type: "image",
        imagePath: packagePlanImages.virtualTraining,
        title: "Virtual Home Training",
        shortTitle: "Virtual Home Training",
        paragraph: [
            "Are you looking to achieve your fitness goals from the comfort of your home?",
            "Our \"Total Body Transformation\" monthly package is designed to help you get in shape, build strength, and improve your overall well-being through virtual training sessions. With a focus on personalized workouts and expert guidance, this package is perfect for individuals who prefer the comfort of their home gym or just to stay fit with minimal equipment\n" +
            "\n",
            "Package Includes: ",
            [
                "A comprehensive fitness assessment to determine your current fitness level, goals, and any specific needs or restrictions.",
                "A customized workout plan designed to help you achieve your specific fitness objectives, whether it's weight loss, muscle gain, or improved endurance.",
                "3, 1-hour virtual training sessions per week (12 sessions per month) conducted via video calls using Google Meet. These sessions will include a variety of workouts, including strength training, cardio, and flexibility exercises.",
                "Dietary recommendations and guidance to complement your fitness regimen.",
                "Bi-weekly check-ins and progress assessments to track your results and make necessary adjustments to your program.",
                "Access to Trainerize, where you can view your workout plans, track your progress, and communicate with me.",
                "Ongoing support and communication with your trainer for questions, motivation, and guidance outside of scheduled sessions.",
                "Please allow UP TO 7 DAYS for your initial new program to be sent."
            ],
            "Additional Options:",
            [
                "Clients can opt for a 3-month or 6-month package for a discounted rate.",
                "There is also an option for a bi-weekly virtual training package for those seeking a lighter commitment."
            ],
        ],
        serviceProductId: ["prod_Ps2tTCdCz6kDB1", "prod_Ps7l1f4tonwzNQ"],
        form: { /** Lets add a drop down menu so the client can pick the diet they would like to focus on.
           Paleo
           Vegan
           * */},
      },
      {
        id: "bodybuilding-plan",
        type: "image",
        imagePath: show.wideShot  ,
        sliderImage: show.wideShot,
        title: "Bodybuilding Contest Preparation",
        shortTitle: "Bodybuilding Contest Plan",
        paragraph: [
            "This 16-week program is specially tailored for competitive bodybuilders aiming to achieve extraordinarily low body fat levels while building significant lean muscle mass. Throughout this journey, you'll have the privilege of collaborating closely with the elite 1% Better Team.",
            [
            "Initial Assessment",
            "Customized nutrition and sports nutrition program designed to help you reach your all-time best look",
            "Weekly high-intensity workouts – 4 workouts per week covering all major body parts, and even up to 7 days.",
            "Weekly Posing Sessions online, and optional Massage Therapy with Vaughn",
            "Unlimited email support (allow 24-48 hours for responses)",
            "Phone support as needed (we’ll be talking a lot in the final weeks)",
            "Coaching on all the fine details such as tanning, sodium manipulation, etc.",
            "Weekly progress assessments. Changes made based on results seen week to week.",
            "At the moment we can attend Tri state area locations\n" +
            "Please allow UP TO 7 DAYS for your initial new program to be sent."
            ],

            "For my online coaching service, I offer a subscription service, with the flexibility for you to cancel anytime. Please note that we do not provide refunds, as you have the freedom to manage your subscription according to your preferences. If youd like lower installment plan for the longern packages schedule a call and we can arrange it."
        ],
        serviceProductId: ["prod_Ps48XS1j9rUueV", "prod_Ps7kkZ9uxHKZxD"],
        form: { /** Lets add a drop down menu so the client can pick the diet they would like to focus on.
           Paleo
           Vegan
           * */},
      }
    ],
  },
  steps: [
    {
      step: "1",
      content:
        "Tailored Fitness Programs Created Just for You - Customized, Versatile Exercise Routines, Suitable for Both Gym and Home Settings",
    },
    {
      step: "2",
      content:
        "Personalized Nutrition Plan at Your Fingertips - Effortless, Customized Meal Solutions Designed to Align with Your Lifestyle and Dietary Requirements.",
    },
    {
      step: "3",
      content:
        "We Monitor Your Fitness Journey Together - Weekly Progress Updates to Help You Stay Committed and Stay on Course Toward Achieving Your Objectives.",
    },
    {
      step: "4",
      content:
        "Ongoing Connection - I'm Here to Offer Support and Motivation at Each Stage of Your Fitness Voyage.",
    },
    {
      title: "WHY CHOOSE ONLINE COACHING?",
    },
  ],
  contentform: [{
    steps: [
      {
        label: "What is your goal?",
        fields: [
          {
            placeholder: "Your goal",
            type: "select",
            label: "What is your goal?",
            options: [
              { label: "Weight Loss", value: "Weight Loss" },
              { label: "Build Muscle", value: "Build Muscle" },
              { label: "Healthy Lifestyle", value: "Healthy Lifestyle" },
            ],
          },
        ],
      },
      {
        label: "How do you identify?",
        fields: [
          {
            placeholder: "Gender",
            type: "select",
            label: "How do you identify?",
            options: [
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
              { label: "Other", value: "Other" },
            ],
          },
        ],
      },
      {
        label: "I am",
        fields: [
          {
            placeholder: "Age",
            type: "select",
            label: "I am",
            options: [
              { label: "18-30", value: "18-30" },
              { label: "30-40", value: "30-40" },
              { label: "40+", value: "40+" },
            ],
          },
        ],
      },
      {
        label: "What is your motivation to start now?",
        fields: [
          {
            placeholder: "Motivation",
            type: "input",
            label: "What is your motivation to start now?",
          },
        ],
      },
      {
        label:
          "How much are you willing to invest each month to achieve your goals?",
        fields: [
          {
            placeholder: "Investment",
            type: "select",
            label:
              "How much are you willing to invest each month to achieve your goals?",
            options: [
              { label: "$280-$350", value: "$280-$350" },
              { label: "$380-$450", value: "$380-$450" },
              { label: "$480-$550", value: "$480-$550" },
            ],
          },
        ],
      },
      {
        label: "Contact information",
        fields: [
          {
            placeholder: "",
            type: "input",
            label: "Your name",
          },
          {
            placeholder: "",
            type: "input",
            label: "Your email",
          },
          {
            placeholder: "",
            type: "input",
            label: "Phone Number",
          },
          {
            type: "select",
            label: "Country",
            placeholder: "country",
            options: [
              { label: "United States", value: "United States" },
              { label: "Afghanistan", value: "Afghanistan" },
              { label: "Albania", value: "Albania" },
            ],
          },
          {
            placeholder: "",
            type: "input",
            label: "Your Instagram Handle",
          },
          {
            placeholder: "",
            type: "input",
            label: "How'd you hear about Drop Them Fitness?",
          },
        ],
      },
      // Add more steps as needed
    ],
  }, {
    steps: [
      {
        label: "What is your goal?",
        fields: [
          {
            placeholder: "Your goal",
            type: "select",
            label: "What is your goal?",
            options: [
              { label: "Weight Loss", value: "Weight Loss" },
              { label: "Build Muscle", value: "Build Muscle" },
              { label: "Healthy Lifestyle", value: "Healthy Lifestyle" },
            ],
          },
        ],
      },
      {
        label: "How do you identify?",
        fields: [
          {
            placeholder: "Gender",
            type: "select",
            label: "How do you identify?",
            options: [
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
              { label: "Other", value: "Other" },
            ],
          },
        ],
      },
      {
        label: "I am",
        fields: [
          {
            placeholder: "Age",
            type: "select",
            label: "I am",
            options: [
              { label: "18-30", value: "18-30" },
              { label: "30-40", value: "30-40" },
              { label: "40+", value: "40+" },
            ],
          },
        ],
      },
      {
        label: "What is your motivation to start now?",
        fields: [
          {
            placeholder: "Motivation",
            type: "input",
            label: "What is your motivation to start now?",
          },
        ],
      },
      {
        label:
            "How much are you willing to invest each month to achieve your goals?",
        fields: [
          {
            placeholder: "Investment",
            type: "select",
            label:
                "How much are you willing to invest each month to achieve your goals?",
            options: [
              { label: "$280-$350", value: "$280-$350" },
              { label: "$380-$450", value: "$380-$450" },
              { label: "$480-$550", value: "$480-$550" },
            ],
          },
        ],
      },
      {
        label: "Contact information",
        fields: [
          {
            placeholder: "",
            type: "input",
            label: "Your name",
          },
          {
            placeholder: "",
            type: "input",
            label: "Your email",
          },
          {
            placeholder: "",
            type: "input",
            label: "Phone Number",
          },
          {
            type: "select",
            label: "Country",
            placeholder: "country",
            options: [
              { label: "United States", value: "United States" },
              { label: "Afghanistan", value: "Afghanistan" },
              { label: "Albania", value: "Albania" },
            ],
          },
          {
            placeholder: "",
            type: "input",
            label: "Your Instagram Handle",
          },
          {
            placeholder: "",
            type: "input",
            label: "How'd you hear about Brix Fitness?",
          },
        ],
      },
      // Add more steps as needed
    ],
  }],
  slidesData: [
    {
      type: "yTubeVideo",
      id: "ZPp_hzqx-QI",
      title: "",
      description: "",
      ctaText: "Why Choose Online Coaching",
      ctaAction: () => {
        window.location.href = "/why-choose";
      },
    },
    {
      type: "image",
      imagePath: show.showOne,
      title: "Train with Coach Renald",
      description: "",
      ctaText: "Training Plans",
      ctaAction: () => {
        window.location.href = "/training";
      },
    },
  ],
}

export default content;
