import React from "react";
import content from "../../config/content";

import "./steps.scss";

const { steps } = content;

function Steps() {
  return (
    <div className='col-2-img  steps  container text-center'>
      <h1>{steps[4].title}</h1>
      <div className='row w100 md'>
        <div className='w50 col-1'>
          <h2>{steps[0].step}</h2>
          <br />
        </div>
        <div className='w50 col-1 '>{steps[0].content}</div>
      </div>
      <div className='row w100 md ctm_mobile'>
        <div className='w50 col-1 ctm_mobile_1'>
          <h2>{steps[1].step}</h2>
          <br></br>
        </div>
        <div className='w50 col-1 ctm_mobile_2'>{steps[1].content}</div>
      </div>
      <div className='row w100 md'>
        <div className='w50 col-1'>
          <h2>{steps[2].step}</h2>
          <br></br>
        </div>
        <div className='w50 col-1 '>{steps[2].content}</div>
      </div>
      <div className='row w100 md ctm_mobile'>
        <div className='w50 col-1 ctm_mobile_1'>
          <h2>{steps[3].step}</h2>
          <br></br>
        </div>
        <div className='w50 col-1 ctm_mobile_2'>{steps[3].content}</div>
      </div>
    </div>
  );
}

export default Steps;
