import React, { useState, useRef, useEffect } from "react";

import pauseicon from "../../Assets/play.png";
import Nexticon from "../../Assets/arrow-right.png";

import "../../Landing/components/mainContent.scss";
import MoviePlayer from "../../../../common/Player/Player-POC";
import {useWindowSize} from "../../../../hooks/useWindowSize";
import "./styles.scss";
interface IMainContent {
  showContent: boolean;
}
interface ISlide {
  type: string;
  imagePath: string;
  title: string;
  description?: string;
}
const Slider = ({ slides }: { slides: ISlide[] }) => {
  const [currentSlide, setCurrentSlide] = useState<Number>(0);
  const [videostatus, setvideostatus] = useState<Boolean>(false);
  const videoRef = useRef<any>(null);

  const handlePausePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setvideostatus(false);
    } else {
      videoRef.current.pause();
      setvideostatus(true);
    }
  };

  useEffect(() => {
    //  if (videoRef.current.paused) {
    //    setvideostatus(false);
    //  } else {
    //    setvideostatus(true);
    //  }
    const consol = () => {
      setvideostatus(false);

    }
    consol();
  }, [currentSlide]);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide: any) => (prevSlide + 1) % slides.length);
  };
  const handlePrvSlide = () => {
    setCurrentSlide((prevSlide: any) => (prevSlide - 1) % slides.length);
  };
  const [showPlayer, setShowPlayer] = useState<boolean>(true)
  const size = useWindowSize();

  const innerHeight = `${size[1] - 111}px`

  const onEnd = () => {
    // console.log('show me this elment', element)
    setShowPlayer(false)
  }

  useEffect(
      () => {
        // setPlayerState(true)
        if (size[0] < 750) {
          setShowPlayer(false)
        }
      },
      [size]
  )
  return (
    <div className='slider-container'>
      {slides.map((slide: any, index: any) =>
        currentSlide == index ? (
          <div
            key={index}
            className={`slide ${
              index === currentSlide ? "active fade-enter-active" : ""
            }`}>
            {slide.type === "component" && (
                <>{slide.component()}</>
            )}
            {slide.type === "image" && (
              <img
                className='slider_main_img'
                src={slide.imagePath ? slide.imagePath : ""}
                alt={`Slide ${index}`}
                width={500}
              />
            )}
            {slide.type === "video" && (
              <video ref={videoRef} autoPlay onClick={handlePausePlay}>
                <source src={slide.imagePath ? slide.imagePath : ""} />
                Your browser does not support the video tag.
              </video>
            )}
            {slide.type === "yTubeVideo" && (<>
                  <MoviePlayer tubeUrl="ZPp_hzqx-QI" innerHeight={innerHeight} hidePlayer={onEnd} />
                </>
            )}
            <div className='slide-content'>
              <h2>{slide.title}</h2>
              <p>{slide.description || ``}</p>
              {0 == index ? (
                ""
              ) : (
                <button className='prvButton_slider' onClick={handlePrvSlide}>
                  <img src={Nexticon} alt="previous slide"/>
                </button>
              )}
              {slides.length - 1 == index ? (
                ""
              ) : (
                <button className='nextButton_slider' onClick={handleNextSlide}>
                  <img src={Nexticon} alt="next slide"/>
                </button>
              )}

              {slide.type === "video" ? (
                !videostatus ? (
                  ""
                ) : (
                  <button className='pausebtn_slider' onClick={handlePausePlay}>
                    <img src={pauseicon} />
                  </button>
                )
              ) : (
                ""
              )}
              <button
                onClick={slide.ctaAction}
                className='ant-btn ant-btn-primary ant-btn-lg call-to-action_slider'>
                {slide.ctaText}
              </button>
            </div>
          </div>
        ) : (
          ""
        )
      )}
    </div>
  );
};
export default Slider;