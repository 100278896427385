import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import Carousel from './components/Carousel'

import './testimonial.scss'


function TestimonialSlider({ testimonials }: any) {
  const renderArray = () => testimonials.map((item: any) => <>
    <div className='review-grid'>
      <div className="start-box" key={item.title}>
        <img src={item.image.src1} alt="client lifting" />
      </div>
      <div className="main">
        <div className="item1">
          <img src={item.image.src2} alt="client selfie" />
        </div>
        <div className="item2 container w100">
          <div className="content">
            <FontAwesomeIcon icon={faQuoteLeft} />
            <p>"{item.items[0].message[0]}"</p>
            <div className="w100">
              <div className="w50">
                <h2>{item.items[0].name}</h2>
                <p><i>{item.items[0].product}</i></p>
              </div>
              <div className="w50">
                <h3><i>{item.items[0].message[1]}</i></h3>
                <p className='arm'>💪</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="end-box">
        <img src={item.image.src3} alt="client lifting" />
      </div>
    </div>
  </>)
  return (<div className="w100 review">
    {/* {renderArray()} */}
    <Carousel>
      {renderArray()}
    </Carousel>
  </div>)
}

export default TestimonialSlider
