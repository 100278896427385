import showOne from './show-1.jpeg';
import showFull from './showFull.jpg';
import long from './longWideShot.jpg'
import bicepLong from './bicep-flex-long.jpg'
export default {
    showOne: showOne,
    full: showFull,
    wideShot: long,
    bicepLong: bicepLong
}
