import React from 'react';
import { useHistory } from "react-router";
import { useListGridReducer } from './store/useListGridReducer'
import { useSliderReducer } from '../Slider/store/useSliderReducer';
import ItemContainer from './components/ItemContainer'

import { categories } from '../../categories';
import "./mobile-scroll.scss";
import "../../Mobile/"

import { SHOW_SLIDER } from '../Slider/store/constants';

function ListGrid() {
  const history = useHistory();
  const { dataDispatch, showList, actions: { CLOSE_LIST } } = useListGridReducer();
  const { showSlider } = useSliderReducer();

  const backButton = () => {
    dataDispatch(CLOSE_LIST)
    history.push(`/`)
  }
  const infoButton = (e: any) => {
    const { id } = e.target
    dataDispatch(SHOW_SLIDER, id)
  }
  const buyButton = () => { }
  const createRows = () => {
    return categories.map(item => {
      const { name } = item;
      return (
        <ItemContainer
          key={name}
          itemProps={{
            name: name,
            callBacks: [infoButton, buyButton, backButton]
          }}
        />
      )
    });
  };
  return (
    <div className={`mobile-scroll ${showList ? 'show' : ''}`}>
      <div className={`mobile-list-grid ${showSlider ? 'showSlider' : ''}`}>
        {createRows()}
      </div>
    </div>
  )
}

export default ListGrid;
