import React, { useEffect, useReducer } from 'react';
import axios from 'axios'
import { useSliderReducer } from '../../Slider/store/useSliderReducer'
import { useHistory } from "react-router";

import { Button } from 'antd'
import { loadStripe } from '@stripe/stripe-js';

const apiUrl = 'https://4wzylppqv2.execute-api.us-east-1.amazonaws.com/prod'
const publicKey = 'pk_test_51HtOqdF66za4IsBOGGrywPR15oBtgLeGJw1U9U7TI5Vd9qRo8gDu94N0Xbmmx9Q1aaqYoJ9hfUpAvI6k13ypKCni00La0wqPgs'

const fetchCheckoutSession = async ({ quantity }) => {
  return axios.post(`${apiUrl}/checkout`, {
    price: "price_1HvTpHF66za4IsBOHWcSUVtq",
    quantity: quantity,
    locale: "en"
  }).then((res) => res.data);
};

const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};

function reducer(state, action) {
  switch (action.type) {
    case 'useEffectUpdate':
      return {
        ...state,
        ...action.payload,
        price: formatPrice({
          amount: action.payload.unitAmount,
          currency: action.payload.currency,
          quantity: state.quantity,
        }),
      };
    case 'increment':
      return {
        ...state,
        quantity: state.quantity + 1,
        price: formatPrice({
          amount: state.unitAmouynt,
          currency: state.currency,
          quantity: state.quantity + 1,
        }),
      };
    case 'decrement':
      return {
        ...state,
        quantity: state.quantity - 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity - 1,
        }),
      };
    case 'setLoading':
      return { ...state, loading: action.payload.loading };
    case 'setError':
      return { ...state, error: action.payload.error };
    default:
      throw new Error();
  }
}

const Checkout = () => {
  const [state, dispatch] = useReducer(reducer, {
    quantity: 1,
    price: null,
    loading: false,
    error: null,
    stripe: null,
  });
  const history = useHistory();
  const { dataDispatch, actions: { CLOSE_SLIDER } } = useSliderReducer();
  const backButton = () => {
    dataDispatch(CLOSE_SLIDER)
    history.push(`/`)
  }

  useEffect(() => {
    async function fetchConfig() {
      // Fetch config from our backend.
      const { response } = await axios.get(
        `${apiUrl}/retrieve-price?priceId=price_1HvTpHF66za4IsBOHWcSUVtq`
      ).then((res) => res.data);
      // Make sure to call `loadStripe` outside of a component’s render to avoid
      // recreating the `Stripe` object on every render.
      dispatch({
        type: 'useEffectUpdate',
        payload: {
          unitAmount: response.unit_amount,
          currency: response.currency,
          stripe: await loadStripe(publicKey)
        },
      });
    }
    fetchConfig();
  }, []);

  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    dispatch({ type: 'setLoading', payload: { loading: true } });
    const { response: { id } } = await fetchCheckoutSession({
      quantity: state.quantity
    });
    // When the customer clicks on the button, redirect them to Checkout.
    const { error } = await state.stripe.redirectToCheckout({
      sessionId: id
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    if (error) {
      dispatch({ type: 'setError', payload: { error } });
      dispatch({ type: 'setLoading', payload: { loading: false } });
    }
  };

  return (
    <div className="stripe-btns">
      <div className="quantity-setter">
        <button
          className="increment-btn"
          disabled={state.quantity === 1}
          onClick={() => dispatch({ type: 'decrement' })}
        >
          -
        </button>
        <input
          type="number"
          id="quantity-input"
          min="1"
          max="10"
          value={state.quantity}
          readOnly
        />
        <button
          className="increment-btn"
          disabled={state.quantity === 10}
          onClick={() => dispatch({ type: 'increment' })}
        >
          +
        </button>
      </div>

      <Button
        className="buy"
        role="link"
        type="primary"
        onClick={handleClick}
        disabled={!state.stripe || state.loading}
      >
        {state.loading || !state.price
          ? `Loading...`
          : `Buy for ${state.price}`}
      </Button>
      <Button
        onClick={backButton}
      >
        <span>Back</span>
      </Button >

      <div className="sr-field-error">{state.error?.message}</div>
    </div>
  );
};

export default Checkout;
