import React from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import WebApp from '../../container/Body';
import Mobile from '../../Mobile/index';

function Home() {
  const size = useWindowSize();
  const renderLayout = () => {
    if (size[0] > 1400) {
      return <WebApp />;
    }
    return <Mobile />;
  };
  return (
    <div>
      {renderLayout()}
    </div>
  );
}

export default Home;
