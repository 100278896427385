import beach from './beach-body-2.jpeg';
import virtual from './virtual-home-training.jpg';
import nutrition from './nutrition-plan.jpg';
import bulk from './bulk-plan.jpg';
import bodyBuilding from './body-building.jpg';
import defualt from './coach-photo-default.png';
export default {
    beachBody: beach,
    virtualTraining: virtual,
    noPhotoDefault: defualt,
    nutrition: nutrition,
    bulk: bulk,
    bodyBuilding: bodyBuilding
}