import React, { useState, useEffect } from 'react'
import { useWindowSize } from '../../hooks/useWindowSize'
import './gform.scss'

type Props = {
  formSrc: string
}

function GForm({ formSrc }: Props) {
  const [height, setHeight] = useState<number>(2000)
  const [scrolling, setScrolling] = useState<string>('no')

  const size = useWindowSize();
  const width = size[0]
  useEffect(() => {
    if (width > 750) {
      setHeight(size[1] + 100)
      setScrolling('yes')
    }
  }, [width, size])
  return <div className="gform">
    <iframe
      src={`https://docs.google.com/forms/d/e/${formSrc}/viewform?embedded=true`}
      title="Test"
      frameBorder="0"
      scrolling={scrolling}
      width="100%"
      height={height}
    >Loading…
    </iframe>
  </div>
}

export default GForm;
