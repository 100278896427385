import React from 'react';
import Landing from '../Landing';

// CMS HOME
function Home() {
  return (
    <div>
      <Landing />
    </div>
  );
}

export default Home;
