import React from 'react'
import { useParams } from 'react-router-dom';
import GForm from '../../../../common/GForm'

import './forms.scss'

function Forms() {
  // @ts-ignore
  const { formTarget } = useParams();

  return <div className="forms">
    <GForm formSrc={formTarget} />
  </div>
}

export default Forms
