import React from 'react'
// import { useWindowSize } from '../../hooks/useWindowSize'

import ReactPlayer from 'react-youtube'
type Props = {
  tubeUrl: string
  innerHeight: string
  hidePlayer: Function
}
function MoviePlayer({ tubeUrl, innerHeight, hidePlayer }: Props) {
  // const [playerState, setPlayerState] = useState<boolean>(true)
  // const size = useWindowSize();
  const tubeStopped = (element: any) => {
    hidePlayer()
  }
  const _onReady = (event: any) => {
    event.target.playVideo()
  }
  return <>
    <ReactPlayer
      className='react-player'
      videoId={tubeUrl}
      opts={{
        width: '100%',
        height: innerHeight,
        playerVars: {
          mute: 1,
        }
      }}
      onReady={_onReady}
      onEnd={tubeStopped}
    />
  </>
}

export default MoviePlayer
