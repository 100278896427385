import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from '../Home';
import Canceled from "../../../common/StripeCheckout/components/Canceled";
import Success from "../../../common/StripeCheckout/components/Success";
import pageArray from "../Pages";
import WhyChoose from "../Pages/WhyChoose/index";
import CoachPage from "../Pages/CoachPage";
import content from "../config/content";
import PageTemplate from "../Pages/CoachPage/page-template";

function Routes() {
  const renderedRoutes = pageArray.map(page => {
    return <Route exact key={page.key} path={`/${page.key}${page.params}`} component={page.component} />
  })

  return (
    <div className='dropem-fitness'>
      <Switch>
        <Route exact key='home' path='/' component={Home} />
        <Route exact key='WhyChoose' path='/why-choose' component={WhyChoose} />
        <Route exact key='canceled' path='/canceled' component={Canceled} />
        <Route exact key='success' path='/success' component={Success} />
        <Route exact key='training' path='/training' component={CoachPage} />
        {content.training.packages.map((page, index) => (
            <Route
                key={index}
                path={`/packages/${page.id}`}
                render={(props) => <PageTemplate {...props} content={page} index={index} />}
            />
        ))}
        {renderedRoutes}
      </Switch>
    </div>
  );
}

export default Routes;
