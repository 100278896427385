import React from 'react'
import mainConfig from '../../config/content';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "../../common/Slider/slider";


import './mainContent.scss'

interface T {
  slidesData: any[];
}

interface IMainContent<T> {
  showContent: boolean;
  content: T;
}

function MainContent({ showContent, content }: IMainContent<T>) {
  const listAdditions = () => mainConfig.training.packages.map((item) => {
    const id = item.id;
    return {
      ...item,
      type: "image",
      imagePath: item.sliderImage ? item.sliderImage : item.imagePath,
      ctaText: "View Package",
      ctaAction: () => {
        window.location.href = `/packages/${id}`
      }
    };
  });

  const slidesData = [...content.slidesData, ...listAdditions()];

  console.log({slidesData});
  return (
    <div className={`main-content ${showContent ? "show" : ""}`}>
      <Slider slides={slidesData} />
    </div>
  );
}

export default MainContent;

