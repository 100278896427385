import React from 'react'
import './paragraph.scss'

function Paragraph({ parArray }: any) {
  const renderArray = () => parArray.map((item: any) => <p>{item}</p>)
  return (
    <div className="par-build">
      {renderArray()}
    </div>
  )
}

export default Paragraph;
