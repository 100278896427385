import React from 'react';
import { Button } from 'antd';

import './item-container.scss'
import bmx6 from "./b6.jpg"

// Placeholders image, item description
const imgSrc = bmx6;
const itemDesc = "The BMW X6 makes an unshakeable impression with its wide stance, horizontal contours, impressive air intakes, and distinctive headlight designs. Leading lights"


interface IItemContainer {
  itemProps: {
    name: string
    callBacks: any[]
    imgSrc?: string
    itemDesc?: string
  }
}

function ItemContainer({ itemProps }: IItemContainer) {
  const { name, callBacks } = itemProps;

  return (
    <div
      id={name}
      className={`item ${name}`}
      key={'grid' + name}
    >
      <span>{name}</span>
      <img src={imgSrc} alt={`${name}`} />
      <p>{itemDesc}</p>
      <div className="footer">
        <div>
          <Button
            type="primary"
            id={name}
            onClick={callBacks[0]}
          >
            Info
          </Button>
          <Button
            className="back"
            onClick={callBacks[2]}
          >
            <span>Back</span>
          </Button >
        </div>
      </div>
    </div>
  )
}

export default ItemContainer;
