import React from "react";
import Flickity from "react-flickity-component";

import "./flickity.css";

function Carousel(props: any) {
  return (
    <Flickity>
      {props.children}
    </Flickity>
  );
}

export default Carousel
