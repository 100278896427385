import React from 'react';
import Paragraph from '../../../../common/Paragraphs';
import content from '../../config/content'
import { Link } from "react-router-dom";
import { Button } from 'antd'

const { aboutUs } = content;

function AboutUs() {
  return (
    <div className="col-2-img about-us container">
      <h1>{aboutUs.headerText}</h1>
      <div className="row w100 md">
        <div className="w50 col-1">
          <Paragraph parArray={aboutUs.par} />
        </div>
        <div className="w50 col-2">
          <a
            href="https://www.instagram.com/drop_them_fitness/"
          >
            <img src={aboutUs.ig.src} alt="instagram diet post" />
          </a>
          <br></br>
          <Link
            to={aboutUs.button.path}
          >
            <Button
              className="call-to-action"
              type="primary"
              size="large"
            >
              {aboutUs.button.text}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
