import { useSelector, useDispatch } from 'react-redux';
import { CLOSE_LIST, SHOW_LIST } from './constants';
import { IStoreListGrid } from './reducer';

export function useListGridReducer() {
  const storeState = useSelector(({ listGrid }: IStoreListGrid) => listGrid)
  const dispatch = useDispatch();
  const dataDispatch = (actionType: string, data?: any) => dispatch({ type: actionType, payload: data })

  return {
    ...storeState,
    actions: {
      CLOSE_LIST,
      SHOW_LIST
    },
    dataDispatch,
    dispatch
  }
}


