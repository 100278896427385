import React, { useEffect, useState } from 'react'
import Slider from '../../../common/Slider'
import { Cancel, Success, ProductDetail } from './options'
import { useSliderReducer } from '../../../common/Slider/store/useSliderReducer'


function SliderContainer() {
  const [component, setComponent] = useState('default')
  const { targetId } = useSliderReducer();
  const dynamicOptions: any = {
    canceled: <Cancel />,
    success: <Success />,
    productDetail: <ProductDetail />,
    default: <></>
  }
  const optionKeys = Object.keys(dynamicOptions)

  useEffect(() => {
    if (targetId && optionKeys.includes(targetId)) {
      setComponent(targetId)
    } else {
      setComponent('productDetail')
    }
  },
    [targetId, optionKeys]
  )

  const renderView = () => dynamicOptions[component]
  return <>
    <Slider>
      {
        renderView()
      }
    </Slider>
  </>
}

export default SliderContainer;
