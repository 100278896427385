import React from 'react'
import BackBtn from '../../../common/Buttons/Back'

function Success() {
  return <div className="cancel-container">
    <h1>Checkout was Successful</h1>
    <BackBtn />
  </div>
}

export default Success
