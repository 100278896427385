import AboutUs from './AboutUs'
import DietPlans from './DietPlans'
import Testimonials from './Testimonials'
import WorkoutPlans from './WorkoutPlans'
import Forms from './Forms'

const pageArray = [
  {
    key: 'about',
    params: '',
    component: AboutUs,
    name: 'About'
  },
  {
    key: 'why-choose',
    params: '',
    name: 'Why'
  },
  {
    key: 'training',
    params: '',
    name: 'Training'
  },
  {
    key: 'Testimonials',
    params: '',
    component: Testimonials,
    name: 'Testimonials'
  },
  {
    key: 'Forms',
    params: '/:formTarget',
    component: Forms,
    name: 'Forms'
  }
]

export default pageArray;
