import axios from 'axios'
import { formatPriceOptions } from './utilities/format'

const apiUrl: any = window.location.hostname === 'dropemfitness.com' ? 'https://hht6rpl6p9.execute-api.us-east-1.amazonaws.com/prod/' : 'https://kr63uyef7b.execute-api.us-east-1.amazonaws.com/dev/'

export async function getProductPrices(productId: string) {
  const { response } = await axios.get(
    `${apiUrl}/get-all-prices?product=${productId}`
  ).then((res) => res.data)
    .catch(error => {
      throw error
    })
  return {
    productId: productId,
    options: formatPriceOptions(response.data)
  }
}

type payloadProps = {
  priceId: string,
  mode: string
}
export async function fetchCheckoutSession({ priceId, mode }: payloadProps) {
  const { response } = await axios.post(`${apiUrl}/checkout`, {
    price: priceId,
    mode,
    quantity: 1,
    locale: "en"
  }).then((res) => res.data)
    .catch(err => {
      throw err
    })
  return response
};
