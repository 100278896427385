import { combineReducers } from 'redux';
import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import { IAction } from '../../models'

import mobileApp from '../../Mobile/store/reducer';
import slider from '../../common/Slider/store/reducer';
import listGrid from '../../common/ListGrid/store/reducer';

const epics: any = [];

// with error handle 
export const rootEpic = (action$: ActionsObservable<IAction>, state$: StateObservable<void>, dependencies: any) =>
  combineEpics(...epics)(action$, state$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );

export const rootReducer = combineReducers({
  mobileApp,
  slider,
  listGrid
});
