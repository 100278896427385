import React, { useState } from "react";
import content from "../../config/content";
import { Link } from "react-router-dom";
import { Button, message } from "antd";
import "./form.scss";
import dropemConfig from "../../config/dropemFitness";
import bg from "../../Assets/coach-photo.png";

interface StepField {
  type: string;
  label: string;
  placeholder: string;
  options?: { label: string; value: string }[];
}

interface Step {
  label: string;
  fields: StepField[];
}

interface FormData {
  [key: string]: string;
}

type Props = {
    targetForm?: number;
    hideBackgroundImage?: boolean;
}
function Steps({ targetForm = 0, hideBackgroundImage = false }: Props) {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const contentForm = content.contentform[targetForm];
  const [formData, setFormData] = useState<FormData>({});
  const [formErrors, setFormErrors] = useState<{
    [key: string]: string | null;
  }>({});

  const handleNext = () => {
    const currentStepFields: StepField[] = contentForm.steps[currentStep - 1].fields;
    const errors: { [key: string]: string } = {};
    let hasErrors: boolean = false;

    // Check for empty fields
    for (const field of currentStepFields) {
      if (!formData[field.label]) {
        errors[field.label] = `Please enter ${field.label.toLowerCase()}`;
        hasErrors = true;

      }
    }

    if (hasErrors) {
      setFormErrors(errors);
      return;
    }

    if (currentStep === contentForm.steps.length) {
      handleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleChange = (field: string, value: string) => {
    setFormData({
      ...formData,
      [field]: value,
    });
      if (field === "Your email") {
        const emailPattern = /\S+@\S+\.\S+/;
        if (!emailPattern.test(value)) {
          setFormErrors({
            ...formErrors,
            [field]: "Please enter a valid email address",
          });
          return;
        }
      }
        if (field === "Phone Number") {
          const numericPattern = /^[0-9]+$/;
          if (!numericPattern.test(value)) {
            setFormErrors({
              ...formErrors,
              [field]: "Please enter a valid phone number",
            });
            return;
          }
        }
    // Clear error message when user starts typing
    setFormErrors({
      ...formErrors,
      [field]: null,
    });
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const calculateProgressBarWidth = () => {
    const totalSteps: number = contentForm.steps.length;
    const widthPercentage: number =
      ((currentStep - 1) / (totalSteps - 1)) * 100;
    if (currentStep === 1) {
      return `${5}%`;
    } else {
      return `${widthPercentage}%`;
    }
  };

  const renderFormFields = () => {
    if (currentStep > contentForm.steps.length || currentStep < 1) {
      return null; // Invalid step, return null
    }

    const currentStepConfig: Step = contentForm.steps[currentStep - 1];

    return (
      <div>
        <div className='form_input_group'>
          <label>{currentStepConfig.label}</label>
          {currentStepConfig.fields.map((field: StepField, index: number) => (
            <div key={index}>
              {field.type === "select" ? (
                <select
                  value={formData[field.label] || ""}
                  onChange={(e) => handleChange(field.label, e.target.value)}
                  placeholder={field.label}>
                  <option value=''>
                    Select {field.placeholder}
                  </option>
                  {field.options?.map((option, optionIndex) => (
                    <option key={optionIndex} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type='text'
                  placeholder={`Enter ${field.label.toLowerCase()}`}
                  onChange={(e) => handleChange(field.label, e.target.value)}
                />
              )}
              {formErrors[field.label] && (
                <span className='error'>{formErrors[field.label]}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleSubmit = () => {
    // Perform submission logic here
    /**
     * 
     *  logic here
     * 
     */
    message.success("Form submitted successfully!");
    // Clear form data
    setFormData({});
    // Reset to first step
    setCurrentStep(1);
  };

  return (<>
    {
      hideBackgroundImage ? <>
        <div className="no-image form_ctm container">
          <Link key='home' to='/'>
            <img className='logo' src={dropemConfig.logo.img} alt='Logo'/>
          </Link>
          <form>
            <div className='form_progress_bar'>
              <div
                  className='form_progress'
                  style={{width: calculateProgressBarWidth()}}></div>
            </div>
            {renderFormFields()}
            {currentStep < contentForm.steps.length && (
                <div className='form_button_group'>
                  <Button
                      type='primary'
                      onClick={handleBack}
                      className='ant-btn ant-btn-primary ant-btn-lg call-to-action_form'
                      disabled={currentStep <= 1}>
                    Back
                  </Button>
                  <Button
                      type='primary'
                      onClick={handleNext}
                      className='ant-btn ant-btn-primary ant-btn-lg call-to-action_form'>
                    Next
                  </Button>
                </div>
            )}
            {currentStep === contentForm.steps.length && (
                <div className='form_button_group justify-content-end'>
                  <Button
                      type='primary'
                      onClick={handleNext}
                      className='ant-btn ant-btn-primary ant-btn-lg call-to-action_form'>
                    Submit
                  </Button>
                </div>
            )}
            {currentStep > contentForm.steps.length && (
                <div className='form_button_group'>
                  <h3>Form submited successfully.</h3>
                </div>
            )}
          </form>
        </div>
      </> : <>
        <div
            className='col-2-img form_ctm showImage container text-center'
            style={{background: bg}}
        >
          <Link key='home' to='/'>
            <img className='logo' src={dropemConfig.logo.img} alt='Logo'/>
          </Link>
          <form>
            <div className='form_progress_bar'>
              <div
                  className='form_progress'
                  style={{width: calculateProgressBarWidth()}}></div>
            </div>
            {renderFormFields()}
            {currentStep < contentForm.steps.length && (
                <div className='form_button_group'>
                  <Button
                      type='primary'
                      onClick={handleBack}
                      className='ant-btn ant-btn-primary ant-btn-lg call-to-action_form'
                      disabled={currentStep <= 1}>
                    Back
                  </Button>
                  <Button
                      type='primary'
                      onClick={handleNext}
                      className='ant-btn ant-btn-primary ant-btn-lg call-to-action_form'>
                    Next
                  </Button>
                </div>
            )}
            {currentStep === contentForm.steps.length && (
                <div className='form_button_group justify-content-end'>
                  <Button
                      type='primary'
                      onClick={handleNext}
                      className='ant-btn ant-btn-primary ant-btn-lg call-to-action_form'>
                    Submit
                  </Button>
                </div>
            )}
            {currentStep > contentForm.steps.length && (
                <div className='form_button_group'>
                  <h3>Form submited successfully.</h3>
                </div>
            )}
          </form>
        </div>
      </>
    }
  </>);
}

export default Steps;
