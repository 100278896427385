import { useSelector, useDispatch } from 'react-redux';
import { CLOSE_MODAL, SHOW_MODAL } from './constants';
import { IStoreMobile } from './reducer';

export function useMobileReducer() {
  const MobileStore = useSelector(({ mobileApp }: IStoreMobile) => mobileApp)
  const dispatch = useDispatch();
  const dataDispatch = (actionType: string, data?: any) => dispatch({ type: actionType, payload: data })
  return {
    ...MobileStore,
    actions: {
      CLOSE_MODAL,
      SHOW_MODAL
    },
    dataDispatch,
    dispatch
  }
}
