import React from "react";
import "./header.scss";

function Header(props) {
    const { classStyles } = props
    return (<div className={`header ${classStyles ? classStyles : ''}`} >
        {props.children}
    </div>)
}

export default Header;